import * as React from 'react'
import { graphql } from 'gatsby'
import Pagina from '../components/Pagina'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'

import Banner from '../components/Banner/index.js'

import grafismo from '../images/Home/Grafismo.svg'
import lwart from '../images/Business/icons/logo-lwart.svg'
import coleta from '../images/Business/icons/coleta.svg'
import oleos from '../images/Business/icons/oleos.svg'
import residuos from '../images/Business/icons/residuos.svg'
import vitalForce from '../images/Business/icons/logo-vital-force.svg'
import fertilizantes from '../images/Business/icons/fertilizantes.svg'
import ped from '../images/Business/icons/p&d.svg'
import internacional from '../images/Business/icons/internacional.svg'
import play from '../images/Business/play.png'
import play2 from '../images/Business/play2.png'

import './styles/business.scss'

const EssenciaEmpreendedora = () => {
  const intl = useIntl()
  return (
    <div className="container-fluid m-0 p-0 pb-5" id='SectionEssenciaEmpreendedora'>
      <div className="position-relative">
        <div className="container">
          <div className='floatLineGreen'></div>
          <div className="row py-lg-5 p-0 m-0 ">
            <div className="col-lg-7 text-gray lineGreen p-0">
              <p className="mb-4">{parse(intl.formatMessage({ id: 'negocios.sectionEssenciaEmpreendedora.paragrafo1' }))}</p>
              {/* <p className='mb-4'>{parse(intl.formatMessage({ id: 'negocios.sectionEssenciaEmpreendedora.paragrafo2' }))}</p> */}
              <p className='mb-4'>{parse(intl.formatMessage({ id: 'negocios.sectionEssenciaEmpreendedora.paragrafo3' }))}</p>
              <p className='mb-4 font-weight-bold'>{parse(intl.formatMessage({ id: 'negocios.sectionEssenciaEmpreendedora.paragrafo4' }))}</p>
            </div>
            <div className="col-5 position-absolute p-0 grafismoDiv d-lg-block d-none">
              <img src={grafismo} alt="Icone Grafismo" width='650px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Lwart = () => {
  const intl = useIntl()
  const MoldeDesktop = (props) => {
    return (
      <div className='col-3 p-0 m-0 mr-4 mb-5'>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <div className='lineGreen pb-1 mb-2'>
            <div className='d-flex justify-content-center'>
              <img src={props.icon} className='mb-3' alt={props.alt} style={props.style} />
            </div>
          </div>
          <p className='text-white font-weight-bold font-size-15 pb-1'>{props.text}</p>
        </a>
      </div>
    )
  }
  const MoldeMobile = (props) => {
    return (
      <div className='col-12 mr-4 mb-5 justify-content-center'>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <div className='d-flex justify-content-center'>
            <div className='col-5 py-2 d-flex justify-content-center divisorGreen'>
              <img src={props.icon} alt={props.alt} style={props.style} />
            </div>
            <div className='col-7 d-flex align-items-center'>
              <p className='text-white font-weight-bold ml-4 pb-1'>{props.text}</p>
            </div>
          </div>
        </a>
      </div>
    )
  }
  return (
    <div className='container-fluid py-5 bg-gradient' id='SectionLwart'>
      <div className="container">
        <div className="row flex-lg-row-reverse customPadding">
          <div className="col-lg-5 col-12 py-5">
            <img src={lwart} className='w-70' />
            <p className='text-white font-size-18 py-5 mx-3'>{intl.formatMessage({ id: 'negocios.sectionLwart.paragrafo1' })}</p>
            <a href='https://www.lwart.com.br/nossas-solucoes/' target="_blank" rel="noopener noreferrer">
              <button className='d-lg-block d-none btn btn-blue-2 font-weight-bold'>{intl.formatMessage({ id: 'negocios.sectionLwart.botaoDesktop' })}</button>
              <button className='d-block d-lg-none btn btn-blue-2 font-weight-bold'>{intl.formatMessage({ id: 'negocios.sectionLwart.botaoMobile' })}</button>
            </a>
          </div>
          <div className="col-lg-6 col-12">
            <ReactPlayer light={play} playIcon playing controls url='https://www.youtube.com/watch?v=gMY9rKchsMA' width={500} height={281} className='videoMobile' />
            <div className='row d-lg-flex d-none justify-content-between pt-5 mt-2'>
              <MoldeDesktop icon={residuos} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.residuos' })} alt='Icone tambor de oléo' link='https://www.lwart.com.br/gestao-de-residuos/' style={{ width: '2.54em' }} />
              <MoldeDesktop icon={coleta} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.coleta' })} alt='Icone caminhão' link='https://www.lwart.com.br/coleta-de-oleo-lubrificante-usado/' style={{ paddingTop: '1.3em', width: '4.6em' }} />
              <MoldeDesktop icon={oleos} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.oleos' })} alt='Icone galão de oléo' link='https://www.lwart.com.br/oleos-de-alta-performance/' style={{ width: '2.8em' }} />
            </div>
            <div className='row d-lg-none d-flex pt-5 mt-2'>
              <MoldeMobile icon={residuos} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.residuos' })} alt='Icone tambor de oléo' link='https://www.lwart.com.br/gestao-de-residuos/' style={{ width: '2.54em' }} />
              <MoldeMobile icon={coleta} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.coleta' })} alt='Icone caminhão' link='https://www.lwart.com.br/coleta-de-oleo-lubrificante-usado/' style={{ paddingTop: '1.3em', width: '4.6em' }} />
              <MoldeMobile icon={oleos} text={intl.formatMessage({ id: 'negocios.sectionLwart.solucoes.oleos' })} alt='Icone galão de oléo' link='https://www.lwart.com.br/oleos-de-alta-performance/' style={{ width: '2.8em' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const VitalForce = () => {
  const intl = useIntl()
  const MoldeDesktop = (props) => {
    return (
      <div className='col-3 p-0 m-0 mr-4 mb-5'>
        <div className='lineGreen pb-1 mb-2'>
          <div className='d-flex justify-content-center'>
            <img src={props.icon} className='mb-3' alt={props.alt} style={props.style} />
          </div>
        </div>
        <p className='text-white font-weight-bold font-size-15 pb-1'>{props.text}</p>
      </div>
    )
  }
  const MoldeMobile = (props) => {
    return (
      <div className='col-12 mr-4 mb-5 justify-content-center'>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <div className='d-flex justify-content-center'>
            <div className='col-5 py-2 d-flex justify-content-center divisorGreen'>
              <img src={props.icon} alt={props.alt} style={props.style} />
            </div>
            <div className='col-7 d-flex align-items-center'>
              <p className='text-white font-weight-bold ml-4 pb-1'>{props.text}</p>
            </div>
          </div>
        </a>
      </div>
    )
  }
  return (
    <div className='container-fluid py-5 bg-blue' id='SectionVitalForce'>
      <div className="container">
        <div className="row position-relative customPadding">
          <div className="col-lg-5 col-12 py-5 mr-5">
            <img src={vitalForce} className='w-70' />
            <p className='text-white font-size-18 pt-5'>{intl.formatMessage({ id: 'negocios.sectionVitalForce.paragrafo1' })}</p><br />
            <p className='text-white font-size-18 pb-5'>{intl.formatMessage({ id: 'negocios.sectionVitalForce.paragrafo2' })}</p>
            <a href='https://vitalforce.com.br/' target="_blank" rel="noopener noreferrer">
              <button className='btn btn-green font-weight-bold'>{intl.formatMessage({ id: 'negocios.sectionVitalForce.botao' })}</button>
            </a>
          </div>
          <div className="col-lg-6 col-12">
            <ReactPlayer light={play2} playIcon playing controls url='https://www.youtube.com/watch?v=w63oi2ngr9U&ab_channel=VitalForce' width={500} height={281} className='videoMobile' />
            <div className='row d-lg-flex d-none justify-content-between pt-5 mt-2'>
              <MoldeDesktop icon={fertilizantes} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.fertilizantes' })} alt='Icone maçã' style={{ width: '3em' }} />
              <MoldeDesktop icon={ped} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.p&d' })} alt='Icone mão com uma planta' style={{ width: '4.8em', paddingBottom: '3.6px' }} />
              <MoldeDesktop icon={internacional} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.internacional' })} alt='Icone do planeta terra' style={{ width: '4.5em', paddingBottom: '4.8px' }} />
            </div>
            <div className='row d-lg-none d-flex pt-5 mt-2'>
              <MoldeMobile icon={fertilizantes} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.fertilizantes' })} alt='Icone maçã' style={{ width: '3em' }} />
              <MoldeMobile icon={ped} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.p&d' })} alt='Icone mão com uma planta' style={{ width: '4.8em' }} />
              <MoldeMobile icon={internacional} text={intl.formatMessage({ id: 'negocios.sectionVitalForce.solucoes.internacional' })} alt='Icone do planeta terra' style={{ width: '4.5em' }} />
            </div>
          </div>
          {/* <p className='text-white font-size-14 pt-lg-5'>{intl.formatMessage({ id: 'negocios.sectionVitalForce.obs' })}</p> */}
        </div>
      </div>
    </div>
  )
}

const Negocios = ({ data, pageContext }) => {
  const intl = useIntl()

  return (
    <div>
      <Pagina pagina={data.pagina} />
      {/* <Banner banner={data.bannerDesktop.childImageSharp.gatsbyImageData} /> */}
      <Banner title={parse(intl.formatMessage({ id: 'negocios.banner.titulo' }))} banner={data.bannerDesktop.childImageSharp.gatsbyImageData} alt='Banner Negócios' />
      <EssenciaEmpreendedora />
      <Lwart />
      <VitalForce />
    </div>
  )
}

export default Negocios

export const businessQuery = graphql`
  query businessQuery($locale: String){
    pagina: contentfulPagina(path: {eq: "/negocios/"}, node_locale: {regex: $locale}) {
      contentful_id
      node_locale
      metaTitle
      metaDescription
      pageSummary
      pageTitle
      path
      tags
    }
    bannerDesktop: file(relativePath: {eq: "Business/banner.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }`
