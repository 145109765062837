import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import './banner.scss'

const Banner = ({ title, banner, alt }) => {
  return (
    <div className="position-relative" id="Banner">
      <GatsbyImage image={banner} alt={alt} className="banner" />
      <div className="position-absolute above w-100 h-100 align-items-end d-flex fixed-top z-index-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 custom-banner">
              <div className="col-lg-8 p-0 d-flex justify-content-start">
                <div className="p-0 col-lg-12">
                  <h1 className="font-Spinnaker text-white" >{ title }</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner
